import React from "react";

import { useState, useEffect } from "react";
import swipe_gif from "../../resources/gifs/le_swipe.gif";
import { sleep } from "../../resources/globals/methods";

const SliderGifAnim = ({ expandedValue, accID }) => {
    const [showSlideGif, setShowSlideGif] = useState(true);

    const startTimer = async () => {
        setShowSlideGif(true);
        await sleep(5000);
        setShowSlideGif(false);
    };

    function hoverIn() {
        setShowSlideGif(true);
    }

    function hoverOut() {
        setShowSlideGif(false);
    }

    useEffect(() => {
        const el = document.getElementById("swipe-gif-container");
        if (el && accID === expandedValue) {
            el.addEventListener("mouseenter", hoverIn);
            el.addEventListener("mouseleave", hoverOut);
            startTimer();
        }
    }, [expandedValue]);

    return (
        <div
            id="swipe-gif-container"
            className={`${!showSlideGif ? "hidden-gif" : ""}`}
        >
            <div id="swipe-gif">
                <img id="img-gif" src={swipe_gif} alt="Slide GIF" />
            </div>
        </div>
    );
};

export default SliderGifAnim;
