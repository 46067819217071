import "./Footer.scss";
import React from "react";
import { Link as RLink } from "react-router-dom";
import { scroller } from "react-scroll";
import { FaAngleUp } from "react-icons/fa";
import { text_content } from "../../resources/content/site_content";
import { services } from "../../resources/content/site_content";
import Logo from "../common/Logo";

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

import classicLogo from "../../resources/logo/white_logo.svg";

import { Button } from "@material-ui/core";

import { getMeRouterObject } from "../../routes/RouteMethods";
import { sleep } from "../../resources/globals/methods";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear().toString(),
        };
    }
    componentDidMount() {}

    scrollTo = async (goTo, offset) => {
        await sleep(200);

        scroller.scrollTo(goTo, {
            duration: 300,
            smooth: true,
            spy: true,
            offset: offset,
        });
    };

    scrollToTop() {
        scroller.scrollTo("TOP", {
            duration: 300,
            smooth: true,
            spy: true,
        });
    }

    render() {
        return (
            <>
                <footer style={{ height: "fit-content" }}>
                    <div id="footer1">
                        <div className="page-container">
                            <div id="footer1-container">
                                <div id="fel1" className="logo-container-bg">
                                    <Logo logo={classicLogo} />
                                    <p className="justify">
                                        {text_content.home.description1}
                                    </p>
                                </div>

                                <div id="fel2">
                                    <h5>
                                        {
                                            text_content["footer_text"][
                                                "services"
                                            ]
                                        }
                                    </h5>
                                    <div>
                                        {services.map((service, index) => (
                                            <div key={index}>
                                                <RLink
                                                    className="footer_serv-link"
                                                    style={{
                                                        color: "white",
                                                        textDecoration: "none",
                                                    }}
                                                    to={() =>
                                                        getMeRouterObject(
                                                            "/services",
                                                            null,
                                                            null
                                                        )
                                                    }
                                                    onClick={() =>
                                                        this.scrollTo(
                                                            service.id,
                                                            -100
                                                        )
                                                    }
                                                >
                                                    {service.title}
                                                </RLink>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div id="fel3">
                                    <h5>
                                        {
                                            text_content["footer_text"][
                                                "looking-for-job"
                                            ]
                                        }
                                    </h5>
                                    <div>
                                        <p>
                                            <RLink
                                                className="icon_link"
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                                to={() =>
                                                    getMeRouterObject(
                                                        "/contact",
                                                        null,
                                                        null
                                                    )
                                                }
                                                onClick={() => {
                                                    this.scrollTo(
                                                        "contact-parent",
                                                        -100
                                                    );
                                                }}
                                            >
                                                {
                                                    text_content["footer_text"][
                                                        "contactus"
                                                    ]
                                                }
                                            </RLink>
                                        </p>
                                    </div>
                                </div>

                                <div id="fel4">
                                    <h5>CONTACT</h5>
                                    <div>
                                        <p>
                                            <RLink
                                                className="icon_link"
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                                to={() =>
                                                    getMeRouterObject(
                                                        "/contact",
                                                        null,
                                                        null
                                                    )
                                                }
                                                onClick={() => {
                                                    this.scrollTo(
                                                        "google-map-id",
                                                        -100
                                                    );
                                                }}
                                            >
                                                <FaMapMarkerAlt />
                                            </RLink>
                                            {text_content.address.wp.address}
                                        </p>

                                        <p>
                                            {text_content.address.city.name},{" "}
                                            {text_content.address.county.name},{" "}
                                            {text_content.address.country.name}
                                        </p>

                                        <p>
                                            <FaPhoneAlt className="footer_icon" />{" "}
                                            {
                                                text_content.contact.tel
                                                    .default_nr
                                            }
                                        </p>

                                        <p>
                                            <a
                                                className="icon_link"
                                                href={`mailto:${text_content.contact.email.email}`}
                                            >
                                                <FaEnvelope />
                                            </a>{" "}
                                            {text_content.contact.email.email}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="footer2">
                        <div className="page-container">
                            <div id="footer2-container">
                                <div>
                                    {
                                        // text_content["firm_rights"].notices.map(notice =>
                                        //     <p>
                                        //         <RLink className='icon_link'
                                        //         style={{color: 'white', textDecoration: 'none'}}
                                        //         to={getMeRouterObject(`/${notice.id}`, notice.title, null, notice.file, 'notice')}
                                        //         onClick={() => {
                                        //             this.scrollTo('notice-id', -100)
                                        //         }}>
                                        //             {notice.title}
                                        //         </RLink>
                                        //     </p>
                                        // )
                                    }
                                </div>
                                <div style={{ marginTop: "25px" }}>
                                    <p>
                                        {text_content["firm_rights"]["rights"]}{" "}
                                        &copy; {this.state.currentYear}{" "}
                                        {text_content["firm_rights"]["name"]}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div
                    style={{ display: "none" }}
                    id="shortcutArrow"
                    onClick={() => this.scrollToTop()}
                >
                    <Button variant="contained" color="primary">
                        <FaAngleUp />
                    </Button>
                </div>
            </>
        );
    }
}

export default Footer;
