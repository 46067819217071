import React from "react";

const Logo = ({ logo }) => {
    return (
        <>
            <img src={logo} alt="Logo" className="logo" />
        </>
    );
};

export default Logo;
