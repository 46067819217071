import "./Header.scss";

import sliderImages from "../../resources/slider/scripts/Images.js";

import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import React, { useState, useEffect } from "react";

import RouteHistory from "./RouteHistory";

const Slider = ({ sliderHeight }) => {
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div id="slider-container" style={{ height: `${sliderHeight}px` }}>
            <div className="slideImageShade" />
            <Carousel className="carousel-container" fade={false} touch={true}>
                {sliderImages.map((el, index) =>
                    el.show === true ? (
                        <Carousel.Item interval={100000} key={index}>
                            <Image
                                style={{
                                    transform: `translateY(${offsetY * 0.5}px`,
                                }}
                                className="d-block w-100"
                                src={el.src}
                                alt="Slide"
                            />

                            <Carousel.Caption className="page-container">
                                <div>
                                    <h3>{el.title}</h3>
                                    {/* <p>{el.description}</p> */}
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ) : (
                        ""
                    )
                )}
            </Carousel>

            <RouteHistory />
        </div>
    );
};

export default Slider;
