import { services } from "../../resources/content/site_content";
import { text_content } from "../../resources/content/site_content";
import Logo from "../common/Logo";

import CardSlider from "./CardSlider.js";

import React from "react";
import classicLogo from "../../resources/logo/classic_logo.svg";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readyTopSide: false,
            number_of_slide_children:
                window.innerWidth <= 700
                    ? 1
                    : window.innerWidth <= 1200
                    ? 2
                    : 3,
        };
        this.setIntroCardHeight = this.setIntroCardHeight.bind(this);
    }

    componentDidMount() {
        var navbar = document.getElementById("nav-bar");
        var introText = document.getElementById("intro-text-container");

        const path = window.location.pathname;

        var sticky = navbar.offsetTop;

        const setIntroCard = () => {
            const offset = 50;
            if (path === "/") {
                if (document.getElementById("slide-intro-card"))
                    this.changeHeight();

                if (
                    window.pageYOffset - offset >= sticky &&
                    this.state.readyTopSide === false
                ) {
                    //introText.parentElement.classList.add('slide-intro-card')
                    introText.parentElement.setAttribute(
                        "id",
                        "slide-intro-card"
                    );
                    this.setState({ readyTopSide: true });
                    this.changeHeight();
                } else if (
                    window.pageYOffset - offset < sticky &&
                    this.state.readyTopSide === true
                ) {
                }
            }
        };

        setIntroCard();

        window.addEventListener("scroll", setIntroCard);
        window.addEventListener("resize", this.changeHeight);
    }

    changeHeight = () => {
        const path = window.location.pathname;
        if (path === "/") {
            var target = document.getElementById("introCardContainer");
            let h = this.setIntroCardHeight();
            target.style.height = h;

            if (window.innerWidth <= 700) {
                if (this.state.number_of_slide_children !== 1) {
                    this.setState({ number_of_slide_children: 1 });
                }
            } else if (window.innerWidth <= 1200) {
                if (this.state.number_of_slide_children !== 2) {
                    this.setState({ number_of_slide_children: 2 });
                }
            } else {
                if (this.state.number_of_slide_children !== 3) {
                    this.setState({ number_of_slide_children: 3 });
                }
            }
        }
    };

    setIntroCardHeight = () => {
        var el = document.getElementById("slide-intro-card");

        const offset = el ? (el.offsetTop === 0 ? -90 : el.offsetTop) : 0;

        return (el ? el.offsetHeight + offset : 200) + "px";
    };

    render() {
        return (
            <div>
                <div id="introID" className="page-container">
                    <div>
                        <div
                            id="introCardContainer"
                            style={{ height: this.setIntroCardHeight() }}
                        >
                            <div>
                                <div id="intro-text-container">
                                    <h2>{text_content.home.welcome}</h2>
                                    <p className="justify">
                                        {text_content.home.description1}
                                    </p>
                                    <p className="justify">
                                        {text_content.home.description2}
                                    </p>
                                    <p className="justify">
                                        {text_content.home.description3}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-container">
                    <div>
                        <div className="albumContainer">
                            <CardSlider
                                content={services}
                                nosc={this.state.number_of_slide_children}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
