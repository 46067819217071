import React from "react";
import { languages } from "../../resources/content/site_content";

const LanguageSelector = () => {
    const langSelect = (lang) => {
        window.sessionStorage.setItem("language", lang);
        window.location.reload(false);
    };

    return (
        <div>
            {languages.map((el, index) => (
                <div
                    title={el.title}
                    className="flag"
                    onClick={() => langSelect(el.lang)}
                    key={index}
                >
                    <img src={el.flag} alt={el.alt} />
                </div>
            ))}
        </div>
    );
};

export default LanguageSelector;
