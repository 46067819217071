import React from 'react'

const Hamburger = ({id, toggleHamburger, onToggle}) => {

    const onClick = () => {
        if(!onToggle)
            return
        onToggle()
    }

    return (
        <div id={id} className={toggleHamburger ? 'hmb_active' : null} onClick={onClick}>
            <div />
            <div />
            <div />
        </div>
    )
}

export default Hamburger
