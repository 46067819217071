import "./Header.scss";

import Slider from "./Slider";
import Navbar from "./Navbar";
import classicLogo from "../../resources/logo/classic_logo.svg";
import { text_content } from "../../resources/content/site_content";

import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

import React from "react";
import Logo from "../common/Logo";
import LanguageSelector from "../common/LanguageSelector";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderHeight: 759,
            readyTopSide: false,
        };
    }

    componentDidMount() {
        var navbar = document.getElementById("nav-bar");
        var goToTopArrow = document.getElementById("shortcutArrow");
        var navbarAux = document.getElementById("navbar-resp-dummy");
        var homeBtn = document.getElementById("home-btn");
        var lastLink = document.getElementById("last-btn");
        var transitionMenu = document.getElementById("transition-menu");

        var sticky = navbar.offsetTop;

        const setStickyNavBar = () => {
            const offset = 150;
            if (
                window.pageYOffset - offset >= sticky &&
                this.state.readyTopSide === false
            ) {
                navbar.classList.add("sticky");
                navbar.classList.remove("before-sticky");
                navbarAux.classList.add("page-container");

                if (window.innerHeight > 320) {
                    goToTopArrow.style.display = "block";
                }

                homeBtn.setAttribute("id", "home-btn-nr");
                lastLink.classList.add("left-side-radius");

                transitionMenu.children[0].style.marginTop = "0";

                this.setState({ readyTopSide: true });
            } else if (
                window.pageYOffset - offset < sticky &&
                this.state.readyTopSide === true
            ) {
                navbar.classList.add("before-sticky");
                navbar.classList.remove("sticky");
                navbarAux.classList.remove("page-container");

                goToTopArrow.style.display = "none";

                homeBtn.setAttribute("id", "home-btn");
                lastLink.classList.remove("left-side-radius");

                if (window.innerHeight > 320) {
                    transitionMenu.children[0].style.marginTop = "24px";
                }

                this.setState({ readyTopSide: false });
            }
        };

        window.addEventListener("scroll", setStickyNavBar);
        window.addEventListener("resize", this.changeHeightOfSlider);

        this.changeHeightOfSlider();
    }

    changeHeightOfSlider = () => {
        const header = document.getElementById("main-header");
        if (!header) return;
        const value = window.innerHeight - header.offsetHeight;
        this.setState({ sliderHeight: value });
    };

    render() {
        return (
            <header id="TOP">
                <div id="main-header">
                    <div>
                        <div className="header1">
                            <div className="page-container">
                                <div className="top-header-container">
                                    <div>
                                        <a
                                            style={{
                                                verticalAlign: "text-top",
                                            }}
                                            className="icon_link"
                                            href={`mailto:${text_content.contact.email.email}`}
                                        >
                                            <FaEnvelope className="icon-left" />
                                        </a>
                                        <p style={{ verticalAlign: "middle" }}>
                                            {text_content.contact.email.email}
                                        </p>
                                    </div>
                                    <p style={{ margin: "0 25px" }}>|</p>
                                    <div>
                                        <FaPhoneAlt className="icon-left" />
                                        <p style={{ verticalAlign: "middle" }}>
                                            {
                                                text_content.contact.tel
                                                    .default_nr
                                            }
                                        </p>
                                    </div>
                                </div>

                                <LanguageSelector />
                            </div>
                        </div>
                        <div className="header2">
                            <div className="page-container">
                                <div>
                                    <div>
                                        <a href="/">
                                            <Logo logo={classicLogo} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="nav-bar"
                            className="before-sticky page-container"
                        >
                            <Navbar
                                toggleHamburger={this.props.toggleHamburger}
                                onToggle={this.props.onToggle}
                            />
                        </div>
                    </div>
                </div>

                <Slider sliderHeight={this.state.sliderHeight} />
            </header>
        );
    }
}

export default Header;
