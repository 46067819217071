import React from "react";
import { useState } from "react";

import { TextField, Button } from "@material-ui/core";

import InProgress from "./InProgress";

import { text_content } from "../../resources/content/site_content";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const validateFields = (email, phone) => {
        let reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (reg.test(String(email).toLowerCase()) === false) {
            setEmail("");
            return "Invalid email format";
        }

        reg = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;

        if (
            reg.test(String(phone).toLowerCase()) === false ||
            phone.toString().length < 5 ||
            phone.toString().length > 50
        ) {
            setPhone("");
            return "Invalid phone format";
        }

        return null;
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!window.confirm("Are you sure you want to submit this message?")) {
            return;
        }

        const validationResult = validateFields(email, phone);

        if (validationResult) {
            alert(`Invalid form! ${validationResult}`);
            return;
        }

        let formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("message", message);

        const res = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/send-email`,
            {
                method: "POST",
                body: formData,
            }
        );

        const resData = await res.json();

        if (res.ok) {
            console.log("Success:", resData.message);
            alert(resData.message);

            resetForm();
        } else {
            console.error("Error:", resData.message);
            alert(resData.message);
        }
    };

    const resetForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        const fileInput = document.getElementById("contained-button-file");
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const validateForm = (e, v_type, setValue) => {
        let value = e.target.value,
            reg,
            cond = false;

        switch (v_type) {
            case "name":
                reg = new RegExp("[^a-zA-Z\\- '`]+");
                cond = true;
                break;

            case "phone":
                reg = new RegExp("^[0-9]+$");
                cond = false;
                break;
        }

        if (value !== "" && reg.test(String(value).toLowerCase()) === cond)
            return;

        setValue(value);
    };

    return (
        <div id="contact-form" className="contact-form-dark">
            {/* <InProgress display={true} alt={text_content.contact.alt} /> */}

            <form onSubmit={onSubmit}>
                <div className="txt-field">
                    <TextField
                        label={text_content.contact.form.name}
                        variant="outlined"
                        value={name}
                        onChange={(e) => validateForm(e, "name", setName)}
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                </div>

                <div id="em_tel_sec" className="tf-inline">
                    <div className="txt-field">
                        <TextField
                            label={text_content.contact.form.email}
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            inputProps={{
                                maxLength: 50,
                            }}
                        />
                    </div>

                    <div className="txt-field">
                        <TextField
                            label={text_content.contact.form.phone}
                            variant="outlined"
                            value={phone}
                            onChange={(e) => validateForm(e, "phone", setPhone)}
                            inputProps={{
                                maxLength: 25,
                            }}
                        />
                    </div>
                </div>

                <div className="txt-field">
                    <TextField
                        label={text_content.contact.form.message}
                        multiline
                        minRows={7}
                        maxRows={7}
                        variant="outlined"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        inputProps={{
                            maxLength: 350,
                        }}
                    />
                </div>

                <Button type="submit" variant="contained" color="primary">
                    {text_content.contact.form.send}
                </Button>
            </form>
        </div>
    );
};

export default ContactForm;
