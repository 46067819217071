import React from "react";

const Map = () => {
    return (
        <div id="google-map-id">
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe
                        id="gmap_canvas"
                        title="XCD LOCATION"
                        src={
                            "https://maps.google.com/maps?q=46.565968,%2024.550976&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        }
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Map;
