import React from "react";
import Viewer from "react-viewer";

const ImageFullView = ({ fullViewImages, visible, closeView }) => {
    return (
        /*<div id='image-full-view-container' 
            style={{display: visible ? 'block' : 'none'}}>

            <div>
                <div id='close-full-view-container'>
                    <FaTimes onClick={closeView} />
                </div>
                <img src={image} />
            </div>
        </div>*/
        <Viewer
            id="image-react-full-view-container"
            visible={visible}
            onClose={closeView}
            images={fullViewImages.img ? fullViewImages.album : []}
            activeIndex={fullViewImages.index}
            zindex={2000}
        />
    );
};

export default ImageFullView;
